import React from 'react'
import PropTypes from 'prop-types'

// TODO: This will hold auth and anything else global

const Wrapper = ({ children }) => {
  return <>{children}</>
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Wrapper
