/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'
import PropTypes from 'prop-types'

import Wrapper from './src/layouts/Wrapper'
import ReactGA from 'react-ga4'

export const wrapPageElement = ({ element }) => {
  ReactGA.initialize('G-5CYK1T6DQR')
  ReactGA.pageview(window.location.pathname + window.location.search)

  return <Wrapper>{element}</Wrapper>
}

wrapPageElement.propTypes = {
  element: PropTypes.node.isRequired,
}
